<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <h2 class="font-weight-black citizen-header">
          {{ citizen.firstName + ' ' + citizen.lastName }}
          <router-link :to="`/citizen/${citizen.id}`">
            <v-btn text
              >Profile<vue-fontawesome
                :icon="['far', 'chevron-right']"
                class="ml-2"
            /></v-btn>
          </router-link>
        </h2>
      </v-col>
      <v-col cols="12">
        TYRO Participation
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <create-participation
          :citizen="citizen"
          :participation="participation"
          :agencyAbbr="agencyAbbr"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import ParticipationService from '@/services/ParticipationService'
import CitizenService from '@/services/CitizenService'
import CreateParticipation from '@/components/participation/CreateParticipation'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'NewParticipation',
  components: {
    Snackbar,
    CreateParticipation
  },
  data() {
    return {
      panel: [0],
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      citizen: {
        firstName: '',
        lastName: ''
      },
      participation: {
        referralData: {
          referralDate: '',
          intakeDate: '',
          startDate: '',
          endDate: '',
          enrollmentDate: '',
          orientationDate: '',
          reasonEnded: '',
          reasonForDismissal: '',
          reasonForDismissalOther: '',
          intakeStaff: '',
          qaReviewStaff: '',
          qaReviewDate: ''
        }
      },
      citizenId: this.$route.params.id,
      // citizen: {},
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    citizenDialog(val) {
      // this.isEdit
      val || this.closeCitizenDialog()
    },
    citizenDialogDelete(val) {
      val || this.closeCitizenDelete()
    },
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    }
  },
  created() {
    // first ditch if they aren't with this agency
    // if (this.$store.state.user.agency.abbr != 'ars') {

    // }
    // if (this.$route.params.agency != this.$store.state.user.agency.abbr) {
    //   this.$router.go(-1)
    //   // this.$router.push('/')
    // }
    CitizenService.getSimple(this.$route.params.id)
      .then(response => {
        // this.organization = response.data
        this.citizen = response.data
        // console.log(response.data)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
    // ParticipationService.get(this.$route.params.id)
    //   .then(response => {
    //     // this.organization = response.data
    //     this.citizen = response.data
    //     // console.log(response.data)
    //   })
    //   .catch(error => {
    //     console.log('We had an error: ' + error)
    //   })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.citizenIndex === -1 ? 'New Citizen' : 'Edit Citizen'
    },
    isEdit() {
      return this.citizenIndex === -1 ? false : true
    },
    agencyAbbr() {
      return this.$store.state.user.agency.abbr || null
    }
  },
  methods: {
    save(date) {
      this.$refs.dateMenu.save(date)
    }
  }
}
</script>
<style lang="scss" scoped>
.v-tab {
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0 !important;
}
.citizen-header {
  letter-spacing: -0.5px !important;
}
</style>
