<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          ref="referralDateMenu"
          v-model="referralDateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="referralDateFormatted"
              label="Referral Date"
              readonly
              v-bind="attrs"
              v-on="on"
              ><vue-fontawesome
                :icon="['fas', 'calendar-alt']"
                slot="prepend-inner"
                class="mt-1 mr-1"
              />{{ referralDateFormatted }}</v-text-field
            >
          </template>
          <v-date-picker
            v-model="referralDateYMD"
            @change="setReferralDate"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="referralDateMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.referralDateMenu.save(referralDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="8">
        <v-text-field
          v-model="participation.referralData.referringAgency"
          id="referringAgency"
          name="referringAgency"
          label="Referring Agency"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="participation.referralData.firstName"
          id="firstName"
          name="firstName"
          label="First Name"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="participation.referralData.lastName"
          :rules="rules"
          id="lastName"
          name="lastName"
          label="Last Name"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="participation.referralData.phone"
          v-mask="'(###) ###-####'"
          id="phone"
          name="phone"
          label="Phone"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          ref="intakeDate"
          v-model="intakeDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="intakeDateFormatted"
              label="Referral Date"
              readonly
              v-bind="attrs"
              v-on="on"
              ><vue-fontawesome
                :icon="['fas', 'calendar-alt']"
                slot="prepend-inner"
                class="mt-1 mr-1"
              />{{ intakeDateYMD }}</v-text-field
            >
          </template>
          <v-date-picker
            v-model="intakeDateYMD"
            @change="saveIntakeDate"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="intakeDate = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.intakeDate.save(intakeDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="6" lg="6"> </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="participation.referralData.ineligibleReason"
          id="ineligibleReason"
          name="ineligibleReason"
          label="Reason for Ineligiblity"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-text-field
          v-model="participation.referralData.declinedReason"
          id="declinedReason"
          name="declinedReason"
          label="Reason for Referral Decline"
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-spacer></v-spacer>
        <v-btn
          elevation="0"
          color="primary"
          class="mr-4"
          @click="saveParticipation"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ParticipationService from '@/services/ParticipationService'
import { DateTime } from 'luxon'

export default {
  name: 'Cor',
  props: {
    citizen: Object,
    participation: Object,
    agencyAbbr: String
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      panel: [0],
      // referralDate: new Date().toISOString().substr(0, 10),
      referralDate: '',
      referralDateMenu: false,
      referralDateYMD: '',
      referralDateFormatted: '',
      intakeDate: '',
      intakeDateYMD: '',
      intakeDateFormatted: '',
      rules: [v => !!v || 'This is required']
    }
  },
  watch: {
    date(val) {
      if (val) {
        this.referralDateFormatted = this.formatDate(this.referralDate)
      }
    }
  },
  methods: {
    setReferralDate(date) {
      this.referralDateFormatted = this.formatDate(date)
    },
    saveIntakeDate(date) {
      this.intakeDateFormatted = this.formatDate(date)
    },
    formatDate(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('MM/dd/yyyy')
      return dt
    },
    formatDateYMD(date) {
      if (!date) return null
      const dt = DateTime.fromISO(date)
        .toUTC()
        .toFormat('yyyy-MM-dd')
      return dt
    },
    async saveParticipation() {
      // console.log('date: ' + this.dateFormatted)
      // alert('component')
      const data = {
        citizen: this.citizen.id,
        createdBy: this.$store.state.user.id,
        agency: this.$store.state.agency.id,
        referralData: {
          referralDate: this.referralDateYMD
        }
      }
      try {
        const participationResult = await ParticipationService.create(data)
        // console.log(participationUpdate)
        if (participationResult.status != 200) {
          return
        }
        this.$router.push('/participation/' + participationResult.data.id)
        console.log(participationResult)
        Object.assign(this.participation, this.participationEdit)
        this.participation.referralData.referralDate = this.referralDateFormatted
        // this.participation.referralData.dateEligible = this.intakeDateFormatted
        // this.closeCitizenDialog()
        this.snackbar.model = true
        this.snackbar.message = 'Participation Contact Info updated'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = 'Participation problem!'
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
