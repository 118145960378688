import axios from '../config/api.config'

class ParticipationService {
  // getAll() {
  //   return axios.get('/participation')
  // }

  get(id) {
    return axios.get(`/participation/${id}`)
  }
  getByCitizen(id) {
    return axios.get(`/participation/citizen/${id}`)
  }

  getByAgency(id, agency) {
    return axios.get(`/participation/citizen/${id}/agency/${agency}`)
  }

  create(data) {
    return axios.post('/participation', data)
  }

  update(id, data) {
    return axios.put(`/participation/${id}`, data)
  }

  delete(id) {
    return axios.delete(`/participation/${id}`)
  }

  // deleteAll() {
  //   return axios.delete(`/participation`)
  // }

  findByTitle(name) {
    return axios.get(`/participation?name=${name}`)
  }
}

export default new ParticipationService()
